import React from 'react';
import TimeAgo from 'timeago-react';
import dateformat from 'dateformat';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import config from 'config';
import { SWAP_TYPE, TYPE } from 'utils/constants';
import { green } from '@mui/material/colors';
import theme, { OxenTheme, colors } from 'theme/oxen';

const hashUrls = {
  [TYPE.OXEN]: config.oxen.txExplorerUrl,
  [TYPE.WOXEN]: config.woxen.txExplorerUrl
};

const RenderHash = ({
  type,
  txHash,
  transferTxHashes,
  classes
}: {
  type: string;
  txHash: string;
  transferTxHashes: Array<string>;
  classes: any;
}) => {
  const hasTransferHashes = transferTxHashes.length > 0;
  const depositHashType =
    type === SWAP_TYPE.OXEN_TO_WOXEN ? TYPE.OXEN : TYPE.WOXEN;
  const transferHashType =
    type === SWAP_TYPE.OXEN_TO_WOXEN ? TYPE.WOXEN : TYPE.OXEN;
  const hashType = hasTransferHashes ? transferHashType : depositHashType;
  const baseUrl = hashUrls[hashType];

  const hashes = hasTransferHashes ? transferTxHashes : [txHash];
  const hashItems = hashes.map(hash => {
    const url = `${baseUrl}/${hash}`;
    return (
      <Typography key={hash} className={classes.hash}>
        <a
          className={classes.hash}
          href={url}
          target='_blank'
          rel='noopener noreferrer'
        >
          {hash}
        </a>
      </Typography>
    );
  });

  if (transferTxHashes.length === 0) {
    return (
      <Box>
        <Typography className={classes.hashTitle}>
          Deposit Transaction Hash
        </Typography>
        {hashItems[0]}
      </Box>
    );
  }

  const swapTitle =
    transferTxHashes.length === 1
      ? 'Swap Transaction Hash'
      : 'Swap Transaction Hashes';
  return (
    <>
      <Typography className={classes.hashTitle}>{swapTitle}</Typography>
      {hashItems}
    </>
  );
};

const RenderTime = ({
  created,
  classes
}: {
  created: number;
  classes: any;
}) => {
  const timestamp = created * 1000 || Date.now();
  const now = Date.now();
  const diff = Math.abs(now - timestamp);
  const dayMs = 24 * 60 * 60 * 1000;

  const showFullDate = diff > dayMs;
  if (showFullDate) {
    const formatted = dateformat(timestamp, 'dd/mm/yyyy');
    return <Typography className={classes.time}>{formatted}</Typography>;
  }

  return <TimeAgo className={classes.time} datetime={timestamp} />;
};

const RenderSwapItem = ({
  uuid,
  type,
  amount,
  txHash,
  transferTxHashes,
  created,
  unconfirmed,
  oxenFee,
  classes
}: {
  uuid: number;
  type: string;
  amount: number; // TODO this might be a string
  txHash: string;
  transferTxHashes: Array<string>;
  created: number;
  unconfirmed: boolean;
  oxenFee: number;
  classes: any;
}) => {
  const isPending = transferTxHashes && transferTxHashes.length === 0;
  const depositCurrency = type === SWAP_TYPE.OXEN_TO_WOXEN ? 'OXEN' : 'WOXEN';
  const fee = (oxenFee || 0) / 1e9;
  const displayAmount = amount / 1e9 - fee;

  let status = 'Completed';
  if (isPending) {
    status = unconfirmed ? 'Waiting for Confirmations' : 'Pending';
  }

  const feeStatus = fee > 0 ? `(${fee} Fee)` : '';

  return (
    <Grid item xs={12} key={uuid}>
      <Box className={classes.item}>
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          className={classes.xsColumn}
        >
          <Typography className={classes.amount}>
            {displayAmount} {depositCurrency} {feeStatus}
          </Typography>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Typography
              className={isPending ? classes.pending : classes.completed}
            >
              {status}
            </Typography>
            <Typography className={classes.timeSeperator}> • </Typography>
            <RenderTime created={created} classes={classes} />
          </Box>
        </Box>
        <Divider variant='middle' className={classes.divider} />
        <RenderHash
          type={type}
          txHash={txHash}
          transferTxHashes={transferTxHashes}
          classes={classes}
        />
      </Box>
    </Grid>
  );
};

const useStyles = makeStyles((theme: OxenTheme) => ({
  root: {
    margin: theme.spacing(2, 0)
  },
  item: {
    padding: theme.spacing(1, 2)
  },
  pending: {
    color: 'rgba(0,0,0,0.33)'
  },
  completed: {
    color: green.A400
  },
  time: {
    color: colors.chainflipMagenta,
    fontSize: '1em'
  },
  timeSeperator: {
    margin: '0 4px'
  },
  divider: {
    margin: '8px 0'
  },
  hashTitle: {
    fontWeight: 600,
    fontSize: '1em',
    marginRight: '4px'
  },
  hash: {
    fontStyle: 'italic',
    overflowWrap: 'break-word',
    color: colors.oxenBlack90,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  amount: {
    fontSize: '1.25em',
    fontWeight: 700
  },
  emptyTitle: {
    padding: theme.spacing(1, 0),
    color: '#c8c8c8'
  },
  xsColumn: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  }
}));

type SwapListProps = {
  swaps: Array<any>;
};

export default function SwapList(props: SwapListProps) {
  const { swaps } = props;

  const classes = useStyles(theme);

  return (
    <Grid item xs={12} className={classes.root}>
      <Grid container direction='column' spacing={1}>
        {!swaps.length ? (
          <Box className={classes.item}>
            <Typography className={classes.emptyTitle}>
              No Transactions Found
            </Typography>
          </Box>
        ) : (
          swaps.map((swap, index) => {
            return <RenderSwapItem key={index} classes={classes} {...swap} />;
          })
        )}
      </Grid>
    </Grid>
  );
}
