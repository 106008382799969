import axios from 'axios';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import {
  WOXEN_CONTRACT_ADDRESS,
  WOXEN_CONTRACT_ABI,
  STAKING_CONTRACT_ADDRESS,
  STAKING_CONTRACT_ABI,
  LP_TOKEN_CONTRACT_ADDRESS,
  LP_TOKEN_CONTRACT_ABI
} from '../constants/wOxen';
import { useContract } from './useContract';
import { BigNumber } from 'ethers';
import { Decimal } from 'decimal.js';
import { useActiveWeb3React } from '.';

const POLLING_INTERVAL = 5000;

export function useWOxenContract() {
  return useContract(WOXEN_CONTRACT_ADDRESS, WOXEN_CONTRACT_ABI);
}

export function useStakingContract() {
  return useContract(STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT_ABI);
}

export function useLPTokenContract() {
  return useContract(LP_TOKEN_CONTRACT_ADDRESS, LP_TOKEN_CONTRACT_ABI);
}

export function useEthereumInfo(pollInterval = 60 * 1000) {
  const token = useWOxenContract();
  const { account } = useActiveWeb3React();
  const [balance, setBalance] = useState<number | undefined>();

  const callback = useCallback(async () => {
    if (!token || !account) {
      setBalance(undefined);
      return;
    }

    try {
      const balance = await token.balanceOf(account);
      if (balance) {
        setBalance(balance.toNumber() / 1e9);
      } else {
        setBalance(0);
      }
    } catch (err) {
      console.error('useEthereumInfo', err);
    }
  }, [account, token]);

  useInterval(callback, pollInterval);

  return { account, balance };
}

export function useBurn() {
  const token = useWOxenContract();
  const { account } = useActiveWeb3React();

  const burn = useCallback(
    async (decimalAmount: number, memo: any) => {
      if (!account) {
        throw new Error('Cannot find connected ETH account');
      }

      const amount = new Decimal(decimalAmount)
        .mul(1e9)
        .round()
        .toString();
      const tx = await token?.burnWithNote(amount, memo);
      await tx.wait();
    },
    [account, token]
  );

  return burn;
}

export function useLPTokenInfo(pollInterval = 60 * 1000) {
  const token = useLPTokenContract();
  const [reserves, setReserves] = useState([
    BigNumber.from(0),
    BigNumber.from(0)
  ]);
  const [totalSupply, setTotalSupply] = useState(BigNumber.from(0));

  const callback = useCallback(async () => {
    if (!token) {
      return;
    }

    try {
      const reserves = await token.getReserves();
      setReserves([reserves[0], reserves[1]]);

      const totalSupply = await token.totalSupply();
      setTotalSupply(totalSupply);
    } catch (err) {
      console.error('useStakingInfo', err);
    }
  }, [token]);

  useInterval(callback, pollInterval);

  return { reserves, totalSupply };
}

export function useStakingInfo(pollInterval = POLLING_INTERVAL) {
  const contract = useStakingContract();
  const [periodFinish, setPeriodFinish] = useState(0);
  const [paused, setPaused] = useState(false);
  const [totalStaked, setTotalStaked] = useState(BigNumber.from(0));
  const [rewardPerSecond, setRewardPerSecond] = useState(BigNumber.from(0));

  const callback = useCallback(async () => {
    if (!contract) {
      return;
    }

    try {
      const periodFinish = await contract.periodFinish();
      setPeriodFinish((periodFinish.toNumber() || 0) * 1000);

      const totalSupply = await contract.totalSupply();
      setTotalStaked(totalSupply);

      const rewardRate = await contract.rewardRate();
      setRewardPerSecond(rewardRate);

      const paused = await contract.paused();
      setPaused(paused);
    } catch (err) {
      console.error('useStakingInfo', err);
    }
  }, [contract]);

  useInterval(callback, pollInterval);

  return { periodFinish, totalStaked, rewardPerSecond, paused };
}

export function useUserStakingInfo(
  address: string | null | undefined,
  pollInterval = POLLING_INTERVAL
) {
  const contract = useStakingContract();
  const [staked, setStaked] = useState(BigNumber.from(0));
  const [earned, setEarned] = useState(BigNumber.from(0));

  const callback = useCallback(async () => {
    if (!contract || !address) {
      return;
    }

    try {
      const staked = await contract.balanceOf(address);
      setStaked(staked);

      const earned = await contract.earned(address);
      setEarned(earned);
    } catch (err) {
      console.error('useUserStakingInfo', err);
    }
  }, [address, contract]);

  useInterval(callback, pollInterval);

  return { staked, earned };
}

export function useAPY() {
  const { rewardPerSecond, totalStaked } = useStakingInfo();
  const { reserves, totalSupply } = useLPTokenInfo();
  const price = useOxenUSDPrice();

  return useMemo(() => {
    if (totalStaked.isZero()) {
      return new Decimal(0);
    }

    const calculateTokenAmount = (reserve: BigNumber) => {
      if (totalSupply.isZero() || totalSupply.isNegative()) {
        return BigNumber.from(0);
      }
      return totalStaked.mul(reserve).div(totalSupply);
    };

    // Contract owns this many tokens
    const wOxenTokens = calculateTokenAmount(reserves[0]);
    const usdtTokens = calculateTokenAmount(reserves[1]);

    const totalValueWOXEN = new Decimal(wOxenTokens.toString())
      .div(1e9)
      .mul(price);
    const totalValueUSDT = new Decimal(usdtTokens.toString()).div(1e6);
    const totalValueStaked = totalValueWOXEN.add(totalValueUSDT);

    const rewardsPerDay = new Decimal(rewardPerSecond.toString())
      .div(1e9)
      .mul(24 * 60 * 60);
    const totalDailyReward = rewardsPerDay.mul(price);

    const dailyYield = totalValueStaked.isZero()
      ? new Decimal(0)
      : totalDailyReward.div(totalValueStaked);

    return dailyYield.mul(365);
  }, [totalStaked, reserves, price, rewardPerSecond, totalSupply]);
}

export function useOxenUSDPrice() {
  const [price, setPrice] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      try {
        const url = 'https://ethereum.oxen.io/api/v1/getPrice';
        const { data } = await axios.get(url);
        if (
          !data ||
          !data['result'] ||
          !data['result']['loki-network'] ||
          !data['result']['loki-network']['usd']
        ) {
          throw new Error('Cannot find USD price');
        }
        setPrice(data['result']['loki-network']['usd']);
      } catch (e) {
        console.error('Failed to fetch Oxen USD price', e);
        setPrice(0);
      }
    };
    fetch();
  }, []);

  return price;
}

export function useStake() {
  const { account, library } = useActiveWeb3React();
  const [error, setError] = useState<Error | undefined>();
  const [status, setStatus] = useState('none');
  const [loading, setLoading] = useState(false);

  const stakingContract = useStakingContract();
  const token = useLPTokenContract();

  const stake = useCallback(
    async (amount: BigNumber) => {
      if (!library || !account) {
        setError(new Error('Web3 not initialized'));
        setLoading(false);
        setStatus('none');
        return;
      }

      setError(undefined);
      setLoading(true);
      setStatus('approve');

      const bnAmount = BigNumber.from(amount);

      try {
        const allowance = await token?.allowance(
          account,
          stakingContract?.address
        );
        if (allowance.lt(bnAmount)) {
          const tx = await token?.approve(stakingContract?.address, bnAmount);
          console.log('Created tx for approval', tx.hash);
          await tx.wait();
        }
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error((e as Error).message));
        }
        console.error(e);
        setLoading(false);
        return;
      }

      try {
        setStatus('staking');
        const tx = await stakingContract?.stake(bnAmount);
        await tx.wait();
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error((e as Error).message));
        }
        console.error(e);
        setLoading(false);
        return;
      }

      setLoading(false);
      setStatus('staked');
      setError(undefined);
    },
    [account, library, stakingContract, token]
  );

  return { stake, status, loading, error };
}

export function useGetReward() {
  const { account, library } = useActiveWeb3React();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(false);

  const stakingContract = useStakingContract();

  const getReward = useCallback(async () => {
    if (!library || !account) {
      setError(new Error('Web3 not initialized'));
      setLoading(false);
      return;
    }

    setError(undefined);
    setLoading(true);

    try {
      const tx = await stakingContract?.getReward();
      await tx.wait();
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error((e as Error).message));
      }
      console.error(e);
      setLoading(false);
      return;
    }

    setLoading(false);
    setError(undefined);
  }, [account, library, stakingContract]);

  return { getReward, loading, error };
}

export function useUnstake() {
  const { account, library } = useActiveWeb3React();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(false);

  const stakingContract = useStakingContract();

  const unstake = useCallback(async () => {
    if (!library || !account) {
      setError(new Error('Web3 not initialized'));
      setLoading(false);
      return;
    }

    setError(undefined);
    setLoading(true);

    try {
      const tx = await stakingContract?.exit();
      await tx.wait();
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error((e as Error).message));
      }
      console.error(e);
      setLoading(false);
      return;
    }

    setLoading(false);
    setError(undefined);
  }, [account, library, stakingContract]);

  return { unstake, loading, error };
}

export function useLPTokenBalance() {
  const [balance, setBalance] = useState(BigNumber.from(0));
  const { account } = useActiveWeb3React();
  const token = useLPTokenContract();
  const callback = useCallback(async () => {
    if (!token || !account) {
      setBalance(BigNumber.from(0));
      return;
    }

    try {
      const balance = await token.balanceOf(account);
      setBalance(balance);
    } catch (err) {
      console.error('useLPTokenBalance', err);
    }
  }, [account, token]);

  useInterval(callback, 5000);

  return balance;
}

function useInterval(callback: Function, delay: number) {
  const savedCallback = useRef<Function>();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current !== undefined) {
        savedCallback.current();
      }
    }
    const id = setInterval(tick, delay);
    tick();
    return () => {
      clearInterval(id);
    };
  }, [callback, delay]);
}
