import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import StyledLabel from '../label';
import makeStyles from '@mui/styles/makeStyles';
import theme, { OxenTheme } from 'theme/oxen';

const useStyles = makeStyles((theme: OxenTheme) => ({
  root: {
    verticalAlign: 'bottom',
    minWidth: '100px',
    display: 'inline-block',
    marginTop: '12px',
    marginBottom: '12px'
  }
}));

type InputProps = {
  label: string;
  helpertext?: string;
  placeholder?: string;
  id?: string;
  defaultValue?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  value?: string | number;
  onChange?: (...args: any[]) => any;
  password?: boolean;
  error?: boolean;
};

function StyledInput(props: InputProps) {
  const {
    helpertext,
    placeholder,
    id,
    defaultValue,
    label,
    fullWidth,
    value,
    onChange,
    error,
    disabled,
    password
  } = props;

  const classes = useStyles(theme);

  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const endAdornment = password ? (
    <InputAdornment position='end'>
      <IconButton
        aria-label='Toggle password visibility'
        onClick={handleClickShowPassword}
      >
        {values.showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  ) : null;

  return (
    <FormControl
      className={classes.root}
      variant='outlined'
      fullWidth={fullWidth}
      error={error}
    >
      <StyledLabel label={label} />
      <OutlinedInput
        id={id}
        placeholder={placeholder}
        fullWidth={fullWidth}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        disabled={disabled}
        type={password ? (values.showPassword ? 'text' : 'password') : 'text'}
        endAdornment={endAdornment}
        color={'secondary'}
      />
      {helpertext && <FormHelperText>{helpertext}</FormHelperText>}
    </FormControl>
  );
}

export default StyledInput;
