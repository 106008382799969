import React, { Component } from 'react';
import Box from '@mui/material/Box';

class ToS extends Component {
  render() {
    return (
      <div>
        <Box display='flex' justifyContent='center' className='title'>
          <h1>wOxen Bridge Terms of Use</h1>
        </Box>

        <Box m='8px' mt='3em' mb='2em' className='readable'>
          <h2>The Platform</h2>
          <p>
            1. The website wOxen Bridge, and any related mobile applications,
            (collectively "Platform") are referred to as “we”, “us” or “our” in
            these Terms of Use.
          </p>
          <p>
            2. The Platform provided and operated by wOxen Bridge allows users
            to deposit particular digital currencies and directly swap them for
            an equivalent on an alternate blockchain. “wOxen Bridge” may also
            refer to the services, website, mobile applications, content,
            products or any other services provided by wOxen Bridge
            (collectively “Services”).
          </p>
          <p>
            3. By making a transaction to the address specified on the website
            in order to use the Services provided by wOxen Bridge, you expressly
            represent and warrant that you have accepted our Terms of Use, and
            any additional terms and conditions displayed on the Platform
            (including without limitation our Privacy Policy and AML-CTF Policy)
            as they apply from time to time.
          </p>
          <p>
            4. We grant you a non-exclusive, revocable, non-transferable licence
            to use the software on a server controlled by us for the sole
            purpose of accessing and obtaining the material on the Platform.
          </p>
          <p>
            5. We may amend, modify, add to, or delete these Terms of Use at our
            discretion and those amendments, modifications, additions or
            deletions apply to your use of the Platform and Services as soon as
            they are displayed on the Platform (whether or not you are aware of
            those amendments, modifications, additions or deletions). All
            subsequent transactions by you will be subject to the amended and
            most current Terms of Use.
          </p>
          <p>
            6. It is your responsibility to ensure that you are compliant with
            the relevant legislation in your jurisdiction before using the
            Platform or Services.
          </p>

          <h2>Risks of Trading and Holding Crypto or Digital Currencies</h2>
          <p>
            7. The trading or holding of coins, tokens, crypto, digital
            currency, or digital assets of the like (collectively "Coins")
            involves significant risks and the losses can be substantial. You
            should carefully consider and assess whether holding Coins is
            suitable for you depending upon your financial circumstances and
            tolerance to risk.
          </p>
          <p>
            8. Coins such as Oxen, Ethereum, and wOxen are unlike traditional
            currencies, goods, or commodities in the market and are subject to
            exceptional risks. Unlike other traditional forms of currency, Coins
            are decentralised and are not backed by a central bank, government
            or legal entities and remain unregulated. As such, the value of
            Coins may fluctuate significantly due to changes in market
            conditions, confidence of investors, competing currencies,
            regulatory announcements or changes, technical problems, or any
            other factors.
          </p>
          <p>
            9. Due to the significant risks of using Coins as well as the impact
            of technology and the international market, wOxen Bridge cannot
            warrant or guarantee that particular Coins will be available on our
            Platform and Services. wOxen Bridge reserves the right to stop
            offering Services in relation to particular Coins on our Platform
            and Services. We will use our reasonable endeavours to notify you if
            we cease to support particular Coins. If this is the case, you will
            not be able to convert Coins into any other currency using this
            platform any longer, and will have to convert the Coins through
            another user, platform, or third party website.
          </p>
          <p>
            10. wOxen Bridge only supports mechanisms such as Metacoins,
            Alt-Coins, Forks, Sidechains, Colour Coins, or any other Coins which
            supplement and interact with digital currency, if wOxen Bridge
            explicitly specifies through our Platform. The Platform will
            participate in Forks, wOxen Bridge’s absolute discretion.
          </p>
          <p>
            11. wOxen Bridge is not responsible or liable for any Coins once
            they are transferred from our Platform to another site, device,
            platform or user. Nor is wOxen Bridge responsible or liable for any
            Coins not converted on our Platform that have been sent into our
            Platform or Coins that have been sent to an incorrect wallet address
            or a wallet address for a different Coin. It is important for you to
            take precautions when transferring and storing your Coins whether on
            our platform or through other sites, wallets or devices. We take no
            responsibility for misappropriation or theft of your Coins. You
            should familiarise yourself with the security measures available
            when using a wallet service or other device for storage. Always take
            a greater degree of care and precaution to secure your passwords
            when operating in a digital environment.
          </p>
          <h2>Your Obligations</h2>
          <p>
            12.You warrant to us that you are a bona fide user of wOxen Bridge’s
            Platform and Services for the purposes of converting Coins.
          </p>
          <p>
            13. You must not:
            <ol>
              <li>
                Engage in any restricted or criminal activities, including but
                not limited to:
              </li>
              <ol>
                <li>Terrorist financing;</li>
                <li>Money laundering;</li>
                <li>Illegal gambling;</li>
                <li>Distributing or funding drugs and drug paraphernalia;</li>
                <li>Malicious hacking;</li>
              </ol>
              <li>
                Knowingly or recklessly provide us with inaccurate or incomplete
                information through the Platform;
              </li>
              <li>
                Use wOxen Bridge’s Platform and Services for the purpose of
                obtaining, processing, distributing, viewing, assessing,
                analysing, copying or replicating any information, methods or
                processes related to the Platform (including without limitation
                by way of data scraping, the use of collection or accumulation
                tools and robotic or scripted responses);
              </li>
              <li>
                Reverse engineer, disassemble or otherwise attempt to construct,
                copy or replicate the Platform’s source code, formulas or
                processes unless publically released in open source repositories
                by us;
              </li>
              <li>
                Interfere with the security of the Platform or the safe use of
                the Platform by others (including without limitation by way of
                distributing viruses, corrupted files or other similar software
                or programs that may damage the operation of any computer
                hardware or software or which are otherwise directed at the
                Website or its users);
              </li>
              <li>
                Use this Platform for any purpose that is unlawful or prohibited
                or in a way which infringes the intellectual property rights or
                other rights of any person (including us);
              </li>
              <li>
                Knowingly or recklessly use and/or take advantage of a technical
                or technological error, loophole or glitch on wOxen Bridge’s
                Platform and Services;
              </li>
              <li>
                Use the Platform or the information contained in it for
                commercial purposes which are competitive to the Platform or our
                business or which would otherwise be detrimental or prejudicial
                to our interests in any way;
              </li>
              <li>
                Use systematic, repetitive or other related methods which are
                designed to generate or obtain repetitive and repeated amounts
                of data or other information from or to the Platform or which
                may otherwise place an unreasonable load on the infrastructure
                of the Platform;
              </li>
              <li>
                Publish, post, distribute, disseminate or send ‘spam material’
                or engage in any communication that is offensive, false,
                unlawful, defamatory, indecent, unfair or inappropriate in any
                way to others, which would reasonably be considered ‘spam’ or
                which is deliberately false, misleading, or deceptive (or likely
                to mislead or deceive);
              </li>
              <li>
                Collect or store personal data about other users of the
                Platform; or
              </li>
              <li>
                Do anything else which may interfere with or negatively affect
                the operation of our Platform, Services or others users.
              </li>
            </ol>
          </p>
          <p>
            14. Any user of wOxen Bridge’s Platform who violates or breaches our
            Terms of Use may have their access terminated, as well as any
            pending transactions cancelled by whatever technical means that are
            possible. You may also be held liable for any losses incurred by
            wOxen Bridge or any other user of the Platform.
          </p>
          <p>
            15. wOxen Bridge encourages users to report any problems or
            vulnerabilities with our Platform and Services by submitting a
            support request on our website or emailing us at wOxen Bridge
            Support. If you notify us in good faith, we will not restrict your
            use of our Platform or Services.
          </p>
          <h2>Using wOxen Bridge</h2>
          <p>
            16. wOxen Bridge will use its best endeavours to ensure that it
            meets its deposit and withdrawal timeframes. From time to time
            factors outside of wOxen Bridge’s control may lead to delays. You
            acknowledge that wOxen Bridge is not responsible for any losses that
            occur due to delays in processing deposits or withdrawals.
          </p>
          <p>
            17. By using wOxen Bridge’s Platform and Services, you acknowledge
            that wOxen Bridge is not responsible for any delays you may
            experience in sending and transferring Coins to and/or from wOxen
            Bridge’s Platform to other platforms, websites, users or wallets.
            These delays may be due to issues beyond wOxen Bridge’s control,
            including issues with third party websites and platforms, wallets,
            congestion of the blockchain and wOxen Bridge moving Coins into a
            secure storage vault or platform.
          </p>
          <p>
            18. In addition to converting Coins, you are only permitted to send
            your Coins to another user, platform, website or to your personal
            wallet. Due to the evolving nature of the technology, any technical
            issues regarding the blockchain and use of the network are beyond
            wOxen Bridge’s control and wOxen Bridge reserves the right to cancel
            transactions you have initiated which are delayed beyond a
            reasonable time or lapse due to time delays. In such circumstances,
            you may need to execute the transaction again or at another time.
          </p>
          <p>
            19. In the circumstance where you have received, acquired, or
            accumulated any funds or assets due to an error, glitch or loophole,
            wOxen Bridge may take reasonable action, including but not limited
            to restricting access or deducting the funds from the account, to
            recover the funds or assets from your wOxen Bridge account. We will
            attempt to notify you if such error has occurred and it is our right
            to recover the misappropriated funds or assets.
          </p>
          <p>
            20. It is your responsibility to check your wOxen Bridge account and
            monitor the movement of your Coins including any pending, lapsed or
            cancelled through your transaction history and to re-execute any
            transactions which fail.
          </p>
          <p>
            21. All deposits and withdrawals made are subject to the limits
            displayed on our Platform and Services. This amount may change from
            time to time due to the blockchain as well as the user demand of
            particular Coins.
          </p>
          <h2>Conversion Services and Fees</h2>
          <p>
            22. A conversion fee may apply to all transactions where Coins are
            converted.
          </p>
          <p>
            23. By using the wOxen Bridge Platform, you accept the conversion
            fees and acknowledge that we may adjust our conversion fees at any
            time. wOxen Bridge will notify you of the conversion fees that apply
            to your transaction at the time of the transaction.
          </p>
          <p>
            24. We may delay, suspend or cancel a conversion transaction if we
            believe the transaction may be subject to illegal activity, fraud,
            including but not limited to the activities outlined in clause 13.
          </p>
          <p>
            25. We reserve the right to delay, suspend or cancel a trade or
            transaction if there are technical reasons that prevent us from
            completing the transaction, or if wOxen Bridge is unable to execute
            the transaction. This may be subject to reliance on a third party
            supplier, where we have reasonable belief that the trade is subject
            to illegal activity or fraud, technical difficulties or subject to a
            law enforcement action.
          </p>
          <p>
            26. By sending a transaction to the Platform using the instructions
            provided to you, you accept that upon the receipt of the converted
            Coin to your nominated address, the coins you sent us are no longer
            yours. We provide no guarantee that upon the conversion of Coins
            that you will be able to convert them back to the original Coin
            format through this Platform.
          </p>
          <h2>Termination</h2>
          <p>
            27. wOxen Bridge may suspend, limit, restrict, deactivate or
            terminate your access to wOxen Bridge’s Platform and Services,
            including your wOxen Bridge account, if:
            <ol>
              <li>
                You gain or attempt to gain unauthorised access to the Platform
                or another wOxen Bridge member’s account;
              </li>
              <li>There is a technical or operational difficulty;</li>
              <li>
                You use the wOxen Bridge Platform in order to perform illegal or
                criminal activities;
              </li>
              <li>
                Your use of the wOxen Bridge Platform is subject to any pending
                investigation, litigation or government proceeding;
              </li>
              <li>You fail to pay or fraudulently pay for any transactions;</li>
              <li>We are unable to support your use;</li>
              <li>
                You breach any terms of this Terms of Use Agreement or Privacy
                Policy; or
              </li>
              <li>
                wOxen Bridge receive a request from a law enforcement or
                government agency to do so.
              </li>
            </ol>
          </p>
          <p>
            28. You may cancel your wOxen Bridge account at any time, following
            the settlement of any pending transactions or trades. You will not
            be charged any fees for cancelling your wOxen Bridge account,
            however you must pay any outstanding amounts owed to us.
          </p>
          <h2>Indemnity</h2>
          <p>
            29. To the maximum extent permitted by law, you agree to indemnify,
            defend and hold harmless wOxen Bridge (and each of its associated
            entities, directors, officers, affiliates, employees, service
            providers, members, representatives and agents, collectively the
            “Indemnified Parties”) from and against all claims, demands,
            actions, suits, proceedings, liabilities, losses, damages,
            penalties, fines, expenses and costs (including reasonable legal
            costs awarded by a court) which arise out of or relate to:
            <ol>
              <li>your conduct and use of our Platform and Services;</li>
              <li>
                your breach of these Terms of Use, wOxen Bridge’s Privacy Policy
                and/or AML-CTF Policy;
              </li>
              <li>your breach of any law, regulation or rule;</li>
              <li>
                your violation or breach of wOxen Bridge’s copyright,
                intellectual property, trade secrets, patents, trademarks,
                service marks or any other proprietary right under law;
              </li>
              <li>
                any information that you provide to us via our Platform and
                Services; or
              </li>
              <li>
                any damage that you may cause to our Platform and Services, to
                any wOxen Bridge user or any other person or entity.
              </li>
            </ol>
          </p>
          <p>
            30. This indemnity includes without limitation, liability relating
            to intellectual property rights, defamation and breaches of privacy.
          </p>
          <h2>Liability</h2>
          <p>
            31. To the maximum extent permitted by law, wOxen Bridge does not
            guarantee the quality, performance or fitness for purpose of the
            Platform and Services or the completeness, accuracy or currency of
            statements, representations and information of others (including
            without limitation data, reports and analyses) provided via the
            Platform and Services and wOxen Bridge will not be liable to any
            person or entity for any direct, indirect, consequential or other
            loss, damage, liability, claim or expense (however caused, including
            due to negligence or breach of contract) which may arise out of, or
            in connection with, the use of the Platform and Services or the use
            of or reliance on information contained on or linked to the Platform
            and Services.
          </p>
          <p>
            32. To the maximum extent permitted by law, the total aggregate
            financial liability of the Indemnified Parties to you, whether in
            contract, tort or statutory liability or otherwise, is limited to
            supplying the Platform and Services again or payment of the cost of
            having the Platform and Services supplied again.
          </p>
          <p>
            33. We do not provide any warranty in relation to your use of wOxen
            Bridge’s Platform and Services and we do not provide any warranty
            that the information displayed on our Platform and in relation to
            the Services is up-to-date, accurate or complete. wOxen Bridge’s
            Platform and Services does not provide information to evaluate
            whether or not you should invest, use and trade in Coins. We give no
            warranties, guarantees or accept any liability (except that which
            cannot be excluded by law) in relation to the statements,
            representations and information of others (including without
            limitation data, reports and analyses) displayed on our Platform and
            in relation to the Services.
          </p>
          <p>
            34. You should independently satisfy yourself as to the information
            provided through wOxen Bridge’s Platform and Services. You agree to
            use wOxen Bridge’s Platform and Services at your sole risk and on an
            “as is” basis.
          </p>

          <h2>Use of our Platform and Services</h2>
          <h3>Security</h3>
          <p>
            35. The transmission of information over the Internet (including to
            or from the Platform) is not completely secure or error free. In
            particular, emails to or from us and information submitted to or
            accessed via this Platform may not be secure and you should use
            discretion in deciding what information you send to us via these
            means.
          </p>
          <p>
            36. Emails to and from us may undergo email filtering and virus
            scanning, including by third party contractors. We do not warrant
            that such filters and scans will be effective in removing viruses or
            other potentially harmful code.
          </p>
          <p>
            37. You acknowledge that you use the Platform and Services (and
            obtain and transmit data to it) entirely at your own risk, that it
            is provided on an ‘as is’ basis and that we do not make any
            representations or warranties as to the security, availability of
            our Platform and Services or that your access or use will be
            uninterrupted, timely or secure.
          </p>
          <p>
            38. wOxen Bridge securely stores Coin private keys and other
            information in order to maintain the security and integrity of the
            system. We are committed to protecting users’ Coins and other
            information we collect from you and hold in highly secure data
            centres operated by a third party. Access to personal information is
            only granted to an employee of wOxen Bridge to carry out duties
            required to support our customers. Strong, multi-factor
            authentication is used and access is revoked when no longer
            required.
          </p>
          <p>
            39. By using our Platform and Services, you agree to provide us with
            current, accurate and complete information, including any personal
            information, and agree to keep such information up to date.
          </p>
          <p>
            40. wOxen Bridge may provide your personal information or any other
            information such as your IP address or when your account was last
            accessed if requested by a law enforcement agency or government
            agency.
          </p>
          <p>
            41. We cannot guarantee the identity of any other user, receiver or
            other party you engage with.
          </p>
          <p>
            42. You are accountable for maintaining and preserving the
            confidentiality of your account information, your personal
            information you provide to wOxen Bridge.
          </p>
          <h3>Linking and Framing</h3>
          <p>
            43. This Platform may contain links to other websites (Third Party
            Website) and may display content or information from other websites
            within frames on this Website (Third Party Content). When you click
            on those links or access Third Party Websites through the Platform,
            we may earn a payment as a result of that advertising or that
            referral.
          </p>
          <p>
            44. We are not responsible or liable for the Third Party Website,
            any Third Party Content nor for any infringement of third party
            intellectual property rights as a result of hosting links to Third
            Party Websites and Third Party Content on the Platform.
          </p>
          <p>
            45. We are not responsible or liable for the handling, use or
            disclosure of any personal information collected by a third party
            (including information collected through a Third Party Website) and
            we are released from any liability resulting from the third party’s
            failure to handle, use and disclose your personal information in
            accordance with the Privacy Act 1988 (Cth).
          </p>
          <p>
            46. We do not warrant the accuracy, currency, or suitability for any
            purpose of the Third Party Websites or Third Party Content nor do we
            endorse the offers, products or services which they promote.
          </p>
          <p>
            47. All statements, representations, offers, products or services
            provided through Third Party Websites and Third Party Content are
            the sole responsibility of the operator of the Third Party Website
            or the author of the Third Party Content and in so far as such links
            to Third Party Websites or Third Party Content are hosted on the
            Platform they are done so at the request of the operator or author
            for the purposes of promoting their offers, products and services.
            Except where expressly stated otherwise, we do not act as agent,
            representative, partner or in any other capacity on behalf of such
            operator or author.
          </p>
          <h3>Cookies</h3>
          <p>
            48. We may use cookies to identify your computer on our server and
            so we can track your use on our Platform. In some instances cookies
            may collect and store personal information about you. Such personal
            information will only be used by us in accordance with our Privacy
            Policy.
          </p>
          <h3>Intellectual Property</h3>
          <p>
            49. All information, graphics, data, prices, charts, video, audio
            and any other material (Platform Material) displayed on or available
            on the Platform, and all the underlying source code and software, is
            owned or used under license by us, except where expressly stated in
            these Terms of Use or elsewhere on the Platform.
          </p>
          <p>
            50. This Platform, all Platform Material and any intellectual
            property which vests in either of them is protected by copyright.
            You may download and print content from this Platform for your own
            personal or internal business purposes only. You must not publish,
            adapt, communicate to the public, distribute to third parties, amend
            or make any other copy of information on this Platform without our
            prior written consent or otherwise do anything which would infringe
            our intellectual property rights in the Platform or the Platform
            Material.
          </p>
          <p>
            51. All trademarks, registered trademarks, product names, logos and
            company names mentioned on this Platform are either owned by us or
            are displayed under licence or with permission from the owner. Those
            third-party marks, logos and names remain the property of their
            respective owners.
          </p>
          <p>
            52. Reference on the Platform to any companies, products, services,
            branding, offers or other information, by trade name, trademark or
            otherwise does not constitute or imply endorsement, sponsorship or
            recommendation by us or the respective trademark owner.
          </p>
          <h3>Privacy</h3>
          <p>
            53. Any personal information we collect about you via this Platform
            will only be used and disclosed by us in accordance with our Privacy
            Policy.
          </p>
          <h2>General</h2>
          <p>
            54. By using wOxen Bridge’s Platform and Services, you accept that
            it is your responsibility to determine whether and to what extent,
            any taxes apply to any transactions or trades you conduct through
            our Services. It is also your responsibility to seek professional
            tax advice with respect to personal, business and self-managed
            superfund wOxen Bridge accounts as well as to ensure that you are
            compliant and that the platform and features satisfy your
            requirements. wOxen Bridge does not provide any advice or take any
            responsibility for information that you receive through our Platform
            and Services.
          </p>
          <p>
            55. The Terms of Use published on the date you view them on the
            Platform supersede all prior versions.
          </p>
          <p>
            56. If any provision of the Terms of Use are held to be invalid,
            unenforceable or illegal for any reason, the remaining parts of the
            Terms of Use will be in full force and effect.
          </p>
          <p>
            57. If we are unable to perform our Services as stipulated in the
            Terms of Use due to circumstances out of our control, including but
            not limited to, change of law, regulations or policy, or an event of
            Force Majeure, we will not be held liable.
          </p>
        </Box>
      </div>
    );
  }
}

export default ToS;
