import React from 'react';
import Box from '@mui/material/Box';
import { StakingInfo } from './info';
import { StakeInfo } from './stake';
import { Header } from 'components';
import { useActiveWeb3React } from '../../hooks';
import makeStyles from '@mui/styles/makeStyles';
import theme, { OxenTheme } from 'theme/oxen';

const useStyles = makeStyles((theme: OxenTheme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '12px'
    }
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center'
  },
  warningText: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(1, 0),
    textAlign: 'center'
  },
  status: {
    padding: '2px'
  },
  error: {
    color: theme.palette.error.dark
  }
}));

function Staking() {
  const { account } = useActiveWeb3React();

  const classes = useStyles(theme);

  return (
    <Box className={classes.root}>
      <Box
        m='4px'
        mt='10px'
        display='flex'
        justifyContent='center'
        className='title'
      >
        <h1 className='textCenter'>wOxen Liquidity Mining</h1>
      </Box>
      <Box
        m='4px'
        display='flex'
        justifyContent='center'
        flexDirection='column'
      >
        <Box display='flex' flexDirection='row'>
          <Header classes={classes} />
        </Box>
        {account && <StakeInfo classes={classes} />}
      </Box>
      <StakingInfo />
    </Box>
  );
}

export default Staking;
