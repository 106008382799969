import React, { Component } from 'react';
import Box from '@mui/material/Box';
import LazyLoad from 'react-lazy-load';
import { Header, SnackBar, ImageLoader, Swap } from 'components';
import { SnackbarVariant } from 'components/SnackBar';

class SwapWrapper extends Component {
  state = {
    snackbar: {
      message: null,
      variant: 'success' as SnackbarVariant,
      open: false
    }
  };

  showMessage = (message: any, variant: any) => {
    const snackbar = {
      message,
      variant: variant || 'error',
      open: true
    };
    this.setState({ snackbar });
  };

  closeMessage = (event: any, reason: any) => {
    if (reason === 'clickaway') return;
    const snackbar = {
      ...this.state.snackbar,
      open: false
    };
    this.setState({ snackbar });
  };

  renderSnackbar = () => {
    const { snackbar } = this.state;
    return (
      <SnackBar
        message={snackbar.message}
        open={snackbar.open}
        onClose={this.closeMessage}
        variant={snackbar.variant}
      />
    );
  };

  renderTitleImage = () => {
    return (
      <Box display='flex' justifyContent='center' className='title'>
        <LazyLoad height={'120px'} className='titleContainer'>
          <ImageLoader
            className='titleImage'
            loadedClassName='titleImageLoaded'
            src='/images/WOXEN_white.svg'
            alt='Logo'
          />
        </LazyLoad>
      </Box>
    );
  };

  render() {
    return (
      <>
        <div className='header-wrapper'>
          <Header />
        </div>
        <Box width={1} id='grid'>
          <Swap showMessage={this.showMessage} />
          {this.renderSnackbar()}
        </Box>
      </>
    );
  }
}

export default SwapWrapper;
