import React, { useState } from 'react';
import clsx from 'clsx';

type ImageLoaderProps = {
  src: string;
  alt: string;
  width?: string | number;
  height?: string | number;
  loadingClassName?: string;
  loadedClassName?: string;
  className?: string;
};

const imageInstances: Record<string, boolean> = {};

export default function ImageLoader(props: ImageLoaderProps) {
  const {
    src,
    alt,
    width,
    height,
    loadedClassName = 'img-loading',
    loadingClassName = 'img-loaded',
    className
  } = props;

  const [loaded, setLoaded] = useState(imageInstances[src]);

  const onLoad = () => {
    imageInstances[src] = true;
    setLoaded(true);
  };

  return (
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={clsx(className, loaded ? loadedClassName : loadingClassName)}
      onLoad={onLoad}
    />
  );
}
