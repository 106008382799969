import React, { useCallback, useEffect, useState } from 'react';
import { Decimal } from 'decimal.js';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { OxenButton } from 'components';
import {
  useAPY,
  useGetReward,
  useLPTokenBalance,
  useStake,
  useStakingInfo,
  useUnstake,
  useUserStakingInfo
} from '../../hooks/wOxen';
import { BigNumber } from 'ethers';
import { useActiveWeb3React } from '../../hooks';
import clsx from 'clsx';

const LP_TOKEN_DECIMALS = 18;
const WOXEN_DECIMALS = 9;

function toString(bignumber: BigNumber, decimals: number) {
  const pow = new Decimal(10).pow(decimals);
  return new Decimal(bignumber.toString())
    .dividedBy(pow)
    .toFixed(decimals)
    .replace(/\.?0+$/, '')
    .toString();
}

function calculatePercentageOwned(staked: BigNumber, totalStaked: BigNumber) {
  return new Decimal(staked.toString())
    .mul(100)
    .div(totalStaked.toString())
    .toDecimalPlaces(2)
    .toString();
}

export function StakeInfo({ classes }: { classes: any }) {
  const { account } = useActiveWeb3React();
  const { periodFinish, totalStaked, paused } = useStakingInfo();
  const { staked, earned } = useUserStakingInfo(account);
  const lpTokenBalance = useLPTokenBalance();
  const apy = useAPY();

  const now = Date.now();
  const isFinished = now > periodFinish;
  const apyPercentage = isFinished
    ? '0'
    : apy
        .mul(100)
        .toDecimalPlaces(2)
        .toString();

  if (!account) {
    return <></>;
  }

  const currentBalanceDecimal = toString(lpTokenBalance, LP_TOKEN_DECIMALS);
  const earnedDecimal = toString(earned, WOXEN_DECIMALS);

  const percentageOwned = calculatePercentageOwned(staked, totalStaked);

  const seconds = (periodFinish - Date.now()) / 1000;
  const days = Math.floor(seconds / 86400);

  return (
    <Box mt='4px'>
      <Box className={classes.actionContainer}>
        {isFinished ? (
          <Typography className={classes.time}>
            Rewards period has ended
          </Typography>
        ) : (
          <Typography className={classes.time}>
            Rewards ending in {days} day{days !== 1 && 's'}
          </Typography>
        )}
        <Typography>Estimated APY: {apyPercentage}%</Typography>
        <Typography>
          Current Balance: {currentBalanceDecimal} LP Tokens
        </Typography>
        <Typography>
          Your stake: {percentageOwned}% of the rewards pool
        </Typography>
        <Typography>Earned: {earnedDecimal} wOXEN</Typography>
      </Box>
      {!paused && !lpTokenBalance.isZero() && !lpTokenBalance.isNegative() && (
        <Stake classes={classes} balance={lpTokenBalance} />
      )}
      {!earned.isZero() && !earned.isNegative() && (
        <GetReward classes={classes} />
      )}
      {!staked.isZero() && !staked.isNegative() && (
        <Unstake classes={classes} />
      )}
    </Box>
  );
}

function Stake({ classes, balance }: { classes: any; balance: BigNumber }) {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { stake, status, loading, error } = useStake();

  useEffect(() => {
    setButtonDisabled(balance.isNegative() || balance.isZero());
  }, [balance]);

  const onStake = useCallback(() => {
    stake(BigNumber.from(balance));
  }, [balance, stake]);

  /*
    <Input
      fullWidth
      value={amount}
      onChange={onChange}
      label="How many LP Tokens do you want to stake? "
      loading={loading}
    />
  */

  return (
    <Box className={classes.actionContainer}>
      <OxenButton
        fullWidth
        label='Stake All'
        disabled={buttonDisabled}
        loading={loading}
        variant='outlined'
        onClick={onStake}
      />
      {error && !loading && (
        <Typography className={clsx(classes.status, classes.error)}>
          {error.message}
        </Typography>
      )}
      {loading && (
        <React.Fragment>
          <Typography className={classes.status}>
            {status === 'approve' ? 'Approving LP Tokens' : 'Staking LP Tokens'}
          </Typography>
          <Typography className={classes.status}>
            Please refer to MetaMask for further instructions
          </Typography>
        </React.Fragment>
      )}
    </Box>
  );
}

function GetReward({ classes }: { classes: any }) {
  const { getReward, loading, error } = useGetReward();
  return (
    <Box className={classes.actionContainer}>
      <OxenButton
        fullWidth
        label='Get Reward'
        loading={loading}
        variant='outlined'
        onClick={getReward}
      />
      {error && !loading && (
        <Typography className={clsx(classes.status, classes.error)}>
          {error.message}
        </Typography>
      )}
      {loading && (
        <Typography className={classes.status}>
          Please refer to MetaMask for further instructions
        </Typography>
      )}
    </Box>
  );
}

function Unstake({ classes }: { classes: any }) {
  const { unstake, loading, error } = useUnstake();
  return (
    <Box className={classes.actionContainer}>
      <OxenButton
        fullWidth
        label='Unstake All'
        loading={loading}
        variant='outlined'
        onClick={unstake}
      />
      {error && !loading && (
        <Typography className={clsx(classes.status, classes.error)}>
          {error.message}
        </Typography>
      )}
      {loading && (
        <Typography className={classes.status}>
          Please refer to MetaMask for further instructions
        </Typography>
      )}
    </Box>
  );
}
