import Grid from '@mui/material/Grid';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { OxenButton } from 'components';

export default function Nav() {
  return (
    <Grid
      id='grid'
      container
      justifyContent='center'
      alignItems='center'
      className='links'
    >
      <NavLink to='/about'>
        <OxenButton label='About wOxen' />
      </NavLink>
      <a href='/swap'>
        <OxenButton label='Swap wOxen' />
      </a>
      {/*
        <NavLink to='/staking'>
          <OxenButton label='Liquidity Mining' />
        </NavLink> >
      */}
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://etherscan.io/token/0xd1e2d5085b39b80c9948aeb1b9aa83af6756bcc5'
      >
        <OxenButton label='Ethereum Contract 🔗' />
      </a>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://docs.oxen.io/using-the-oxen-blockchain/oxen-wallet-guides'
      >
        <OxenButton label='Oxen Wallet 🔗' />
      </a>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://v2.info.uniswap.org/pair/0xbaeca7c35346a8d31811ef971f38603012a12c1e'
      >
        <OxenButton label='wOxen Uniswap 🔗' />
      </a>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://etherscan.io/address/0x66d17a3d12a6d7d1f06e30f91e0cac47f2fb6937'
      >
        <OxenButton label='wOxen Staking Contract 🔗' />
      </a>
    </Grid>
  );
}
