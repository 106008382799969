import React from 'react';
import { useBurn, useEthereumInfo } from '../hooks/wOxen';

export const withEthereum = (Component: any) => ({ ...props }) => {
  const { account, balance } = useEthereumInfo();
  const burn = useBurn();
  const eth = { account, balance };

  return <Component eth={eth} burn={burn} {...props} />;
};
