import React from 'react';
import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Close from '@mui/icons-material/Close';
import Error from '@mui/icons-material/Error';
import Info from '@mui/icons-material/Info';
import Warning from '@mui/icons-material/Warning';
import makeStyles from '@mui/styles/makeStyles';
import theme, { OxenTheme, colors } from 'theme/oxen';
import { SimplePaletteColorOptions } from '@mui/material';
import { amber, green } from '@mui/material/colors';

// Got this from https://material-ui.com/components/snackbars/#snackbars

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info
};

const textClasses = {
  success: 'primaryText',
  warning: 'blackText',
  error: 'primaryText',
  info: 'primaryText'
};

const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const useStyles: Function = makeStyles((theme: OxenTheme) => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: (theme.palette.error as SimplePaletteColorOptions).dark
  },
  info: {
    backgroundColor: (theme.palette.primary as SimplePaletteColorOptions).main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  primaryText: {
    color: theme.palette.text.primary
  },
  blackText: {
    color: colors.oxenBlack80
  }
}));

export type SnackbarVariant = 'success' | 'warning' | 'error' | 'info';

type SnackbarProps = {
  className?: string;
  open: boolean;
  message: any;
  onClose: any;
  variant: SnackbarVariant;
};

function SnackBar(props: SnackbarProps) {
  const { className, message, onClose, variant, open } = props;

  const classes = useStyles(theme);

  const Icon = variantIcon[variant];

  const text = textClasses[variant];
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={open}
      autoHideDuration={6000}
      disableWindowBlurListener
      onClose={onClose}
      ContentProps={{
        'aria-describedby': 'message-id',
        className: clsx(classes[variant], className)
      }}
      message={
        <span id='message-id' className={clsx(classes.message, classes[text])}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message && capitalize(message.toString())}
        </span>
      }
      action={[
        <IconButton
          key='close'
          aria-label='Close'
          color='inherit'
          onClick={onClose}
          size='large'
        >
          <Close className={clsx(classes.icon, classes[text])} />
        </IconButton>
      ]}
    />
  );
}

export default SnackBar;
