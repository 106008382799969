const colors = {
  oxenGreen: '#5BCA5B',
  chainflipCyan: '#47eabb',
  chainflipMagenta: '#ff7a87',
  oxenBlack90: '#0A0A0A',
  oxenBlack80: '#c7f6ff',
  oxenBlack60: '#93e8f9',
  oxenBlack50: '#7E7E7E',
  white: '#fff',
  black: '#000',
  gray: '#e1e1e1',
  lightGray: '#fafafa',
  lightBlack: '#6a6a6a',
  darkBlack: '#141414',
  gradient: 'linear-gradient(to bottom, #78be20 0%, #008522 50%, #005f61 100%)'
};

export default colors;
