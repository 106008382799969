import React from 'react';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import StyledLabel from '../label';
import makeStyles from '@mui/styles/makeStyles';
import theme, { OxenTheme } from 'theme/oxen';

const useStyles = makeStyles((theme: OxenTheme) => ({
  root: {
    verticalAlign: 'bottom',
    minWidth: '100px',
    marginBottom: '12px',
    marginTop: '12px'
  }
}));

type SelectProps = {
  label?: string;
  id?: string;
  value?: string;
  handleChange?: (event: SelectChangeEvent<string>) => void;
  fullWidth?: boolean;
  options?: Array<any>;
  disabled?: boolean;
  allowNull?: boolean;
};

function StyledSelect(props: SelectProps) {
  const {
    label,
    id,
    value,
    handleChange,
    fullWidth,
    options,
    disabled,
    allowNull
  } = props;

  const classes = useStyles(theme);

  return (
    <FormControl
      className={classes.root}
      fullWidth={fullWidth}
      variant='outlined'
    >
      {label && <StyledLabel label={label} />}
      <Select
        native
        value={value}
        onChange={handleChange}
        disabled={disabled}
        fullWidth
        input={<OutlinedInput name='age' id={id} />}
        color={'secondary'}
      >
        {allowNull && <option value={undefined}>select</option>}
        {options
          ? options.map((option: any) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.description}
                </option>
              );
            })
          : null}
      </Select>
    </FormControl>
  );
}

export default StyledSelect;
