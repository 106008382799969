import React from 'react';
import styled from 'styled-components';
import { CardProps, Text } from 'rebass';
import { Box } from 'rebass/styled-components';

const Card = styled(Box)<{
  padding?: string;
  border?: string;
  borderRadius?: string;
}>`
  width: 100%;
  border-radius: 16px;
  padding: 1.25rem;
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
`;
export default Card;

export const LightCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.bg2};
  background-color: ${({ theme }) => theme.bg1};
`;

export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg3};
`;

export const OutlineCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.bg3};
`;

export const WhiteCard = styled(OutlineCard)`
  background-color: #2c2f36;
  color: ${({ theme }) => theme.white};
  font-weight: 500;
`;

export const YellowCard = styled(OutlineCard)`
  background-color: #2c2f36;
  color: ${({ theme }) => theme.yellow2};
  font-weight: 500;
`;

export const PinkCard = styled(OutlineCard)`
  background-color: #2c2f36;
  color: ${({ theme }) => theme.primary1};
  font-weight: 500;
`;

const BlueCardStyled = styled(OutlineCard)`
  background-color: ${({ theme }) => theme.primary5};
  color: ${({ theme }) => theme.primary1};
  border-radius: 12px;
  width: fit-content;
`;

export const BlueCard = ({ children, ...rest }: CardProps) => {
  return (
    <BlueCardStyled {...rest}>
      <Text fontWeight={500} color='#2172E5'>
        {children}
      </Text>
    </BlueCardStyled>
  );
};
