import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import StyledLabel from '../label';
import makeStyles from '@mui/styles/makeStyles';
import theme, { OxenTheme } from 'theme/oxen';

const useStyles = makeStyles((theme: OxenTheme) => ({
  root: {
    minWidth: '100px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '12px 0'
  }
}));

function StyledCheckbox(props: any) {
  const {
    // helpertext,
    id,
    label,
    fullWidth,
    checked,
    onChange,
    error,
    disabled
  } = props;

  const classes = useStyles(theme);

  return (
    <FormControl
      className={classes.root}
      variant='outlined'
      fullWidth={fullWidth}
      error={error}
    >
      <Checkbox
        id={id}
        // TODO confirm what this does?
        // helpertext={helpertext}
        checked={checked}
        onChange={onChange}
        value={id}
        disabled={disabled}
      />
      <StyledLabel label={label} style={{ margin: 0 }} />
    </FormControl>
  );
}

StyledCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  helpertext: PropTypes.string,
  id: PropTypes.string,
  defaultValue: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func
};

export default StyledCheckbox;
