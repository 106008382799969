import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { Input, OxenButton, Select } from 'components';
import { SWAP_TYPE, TYPE } from 'utils/constants';
import config from 'config';
import { withEthereum } from '../withEtherum';
import { common } from 'theme';
import theme, { OxenTheme } from 'theme/oxen';
import { SelectChangeEvent } from '@mui/material/Select';

const walletCreationUrl = {
  [TYPE.OXEN]: config.oxen.walletCreationUrl,
  [TYPE.WOXEN]: config.woxen.walletCreationUrl
};

const options = [
  {
    value: SWAP_TYPE.OXEN_TO_WOXEN,
    description: 'OXEN to WOXEN'
  },
  {
    value: SWAP_TYPE.WOXEN_TO_OXEN,
    description: 'WOXEN to OXEN'
  }
];

const useStyles = makeStyles((theme: OxenTheme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '600px'
    },
    ...common.section
  },
  button: {
    marginTop: '24px'
  },
  createAccount: {
    fontSize: '0.8rem',
    textDecoration: 'underline',
    textAlign: 'right',
    marginBottom: '16px',
    cursor: 'pointer',
    display: 'inline-block',
    color: 'rgba(0,0,0,0.6)'
  }
}));

type SwapSelectionProps = {
  swapType: string;
  onSwapTypeChanged: (...args: any[]) => any;
  onNext: (...args: any[]) => void;
  loading: boolean;
  eth: any;
};

function SwapSelection(props: SwapSelectionProps) {
  const {
    swapType,
    onSwapTypeChanged: _onSwapTypeChanged,
    onNext: _onNext,
    loading,
    eth
  } = props;

  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState(false);

  const getAddressType = () => {
    return swapType === SWAP_TYPE.OXEN_TO_WOXEN ? TYPE.WOXEN : TYPE.OXEN;
  };

  const onAddressChanged = (event: any) => {
    setAddress(event.target.value);
  };

  const onNext = () => {
    const isValidAddress = address && address.length > 0;
    setAddressError(!isValidAddress);
    if (isValidAddress) {
      _onNext(address);
    }
  };

  const updateAddress = useCallback(() => {
    const _address =
      swapType === SWAP_TYPE.OXEN_TO_WOXEN ? eth.account || '' : '';
    setAddress(_address);
  }, [eth?.account, swapType]);

  const onSwapTypeChanged = (event: SelectChangeEvent<string>) => {
    _onSwapTypeChanged(event.target.value);
    updateAddress();
  };

  const addressType = getAddressType();
  const inputLabel =
    addressType === TYPE.OXEN
      ? 'Oxen Destination Address'
      : 'ETH Destination Address';
  const inputPlaceholder = addressType === TYPE.OXEN ? 'L...' : '0x...';

  const url = walletCreationUrl[addressType];

  const classes = useStyles(theme);

  useEffect(() => {
    updateAddress();
  }, [updateAddress]);

  return (
    <Grid item xs={12} className={classes.root}>
      <Select
        fullWidth
        label='Swap Type'
        options={options}
        value={swapType}
        handleChange={onSwapTypeChanged}
        disabled={loading}
      />
      <Input
        fullWidth
        label={inputLabel}
        placeholder={inputPlaceholder}
        value={address}
        error={addressError}
        onChange={onAddressChanged}
        disabled={loading}
      />
      <Grid container justifyContent='flex-end'>
        <a
          className={classes.createAccount}
          href={url}
          target='_blank'
          rel='noopener noreferrer'
        >
          Don't have an account? Create one
        </a>
      </Grid>
      <OxenButton
        fullWidth
        label='Next'
        variant='outlined'
        loading={loading}
        onClick={onNext}
      />
    </Grid>
  );
}

const EthSwapSelection = withEthereum(SwapSelection);
export default EthSwapSelection;
