import Box from '@mui/material/Box';
import React from 'react';
import { ImageLoader } from 'components';
// eslint-disable-next-line
import LazyLoad from 'react-lazy-load';
import { NavLink } from 'react-router-dom';

export default function TitleImage() {
  return (
    <Box display='flex' justifyContent='center' className='title'>
      <NavLink to='/'>
        <LazyLoad height={'120px'} className='titleContainer'>
          <ImageLoader
            className='titleImage'
            loadedClassName='titleImageLoaded'
            src='/images/WOXEN_white.svg'
            alt='Logo'
            width='330'
            height='100'
          />
        </LazyLoad>
      </NavLink>
    </Box>
  );
}
