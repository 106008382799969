import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { ExternalLink } from '../../theme';

const InfoCard = styled.button<{ active: number }>`
  background-color: ${({ theme, active }) => (active ? theme.bg3 : theme.bg2)};
  padding: 1rem;
  outline: none;
  border: 1px solid;
  border-radius: 12px;
  width: 100% !important;
  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.primary1};
  }
  border-color: ${({ theme, active }) =>
    active === 1 ? 'transparent' : theme.bg3};
`;

const OptionCard = styled(InfoCard as any)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 1rem;
`;

const OptionCardLeft = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
  justify-content: center;
  height: 100%;
`;

const StyledOptionCardClickable = styled(OptionCard as any)<{
  clickable: number;
}>`
  margin-top: 0;
  &:hover {
    cursor: ${({ clickable }) => (clickable === 1 ? 'pointer' : '')};
    border: ${({ clickable, theme }) =>
      clickable === 1 ? `1px solid ${theme.primary1}` : ''};
  }
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

const OptionCardClickable = (props: {
  children: ReactNode;
  id: string;
  clickable: number;
  active: number;
  onClick?: (...args: any[]) => void;
}) => {
  const { children, id, onClick, clickable, active } = props;
  return (
    <StyledOptionCardClickable
      id={id}
      onClick={onClick}
      clickable={clickable}
      active={active}
    >
      {children}
    </StyledOptionCardClickable>
  );
};

const GreenCircle = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  justify-content: center;
  align-items: center;

  &:first-child {
    height: 8px;
    width: 8px;
    margin-right: 8px;
    background-color: ${({ theme }) => theme.green1};
    border-radius: 50%;
  }
`;

const CircleWrapper = styled.div`
  color: ${({ theme }) => theme.green1};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderText = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  color: ${props =>
    props.color === 'blue'
      ? ({ theme }) => theme.primary1
      : ({ theme }) => theme.text1};
  font-size: 1rem;
  font-weight: 500;
`;

const SubHeader = styled.div`
  color: ${({ theme }) => theme.text1};
  margin-top: 10px;
  font-size: 12px;
`;

const IconWrapper = styled.div<{ size?: number | null }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '24px')};
    width: ${({ size }) => (size ? size + 'px' : '24px')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`;

export default function Option({
  link = null,
  clickable = true,
  size,
  onClick,
  color,
  header,
  subheader = null,
  icon,
  active = false,
  id
}: {
  link?: string | null;
  clickable?: boolean;
  size?: number | null;
  onClick?: (...args: any[]) => void;
  color: string;
  header: React.ReactNode;
  subheader: React.ReactNode | null;
  icon: string;
  active?: boolean;
  id: string;
}) {
  const content = (
    <OptionCardClickable
      id={id}
      onClick={onClick}
      clickable={clickable && !active ? 1 : 0}
      active={active ? 1 : 0}
    >
      <OptionCardLeft>
        <HeaderText color={color}>
          {active ? (
            <CircleWrapper>
              <GreenCircle>
                <div />
              </GreenCircle>
            </CircleWrapper>
          ) : (
            ''
          )}
          {header}
        </HeaderText>
        {subheader && <SubHeader>{subheader}</SubHeader>}
      </OptionCardLeft>
      <IconWrapper size={size}>
        <img src={icon} alt={'Icon'} />
      </IconWrapper>
    </OptionCardClickable>
  );
  if (link) {
    return <ExternalLink href={link}>{content}</ExternalLink>;
  }

  return content;
}
