import React from 'react';
import Box from '@mui/material/Box';

// function faq() {
//   return (
//     <Box>
//       <h1 className='textCenter'>FAQ</h1>
//       <p>
//         These are some of the most commonly asked questions about our Liquidity
//         Rewards Program. The Liquidity Rewards Program is an initiative to
//         encourage people to provide liquidity for the{' '}
//         <a
//           target='_blank'
//           rel='noopener noreferrer'
//           href='https://info.uniswap.org/pair/0xbaeca7c35346a8d31811ef971f38603012a12c1e'
//         >
//           wOXEN/USDT pair on Uniswap
//         </a>{' '}
//         beyond the usual fees collected from Uniswap.
//       </p>
//       <p>
//         This program enables liquidity mining, commonly referred to as yield
//         farming.{' '}
//       </p>

//       <h2>What is yield farming?</h2>
//       <p>
//         Yield farming allows you to use your cryptocurrency holdings to earn
//         rewards. Generally, this is done by either participating or staking in
//         various blockchain applications.
//       </p>
//       <p>
//         Yield farming is most closely associated with the DeFi space, where
//         rewards are typically earned by providing the liquidity required to
//         enable the functionality of various DeFi applications.
//       </p>
//       <p>
//         Our Liquidity Rewards Program allows you to earn wOXEN in return for
//         providing liquidity to the wOxen/USDT pool on{' '}
//         <a
//           target='_blank'
//           rel='noopener noreferrer'
//           href='https://uniswap.org/'
//         >
//           Uniswap
//         </a>
//         .
//       </p>

//       <h2>How do I participate?</h2>
//       <p>
//         In order to provide liquidity for the wOXEN/USDT pool, you will need to
//         hold both wOXEN and USDT. USDT can be easily acquired using traditional
//         exchanges or swapping another asset (such as ETH) via Metamask or
//         Uniswap. You can then acquire wOXEN either by swapping USDT for wOXEN on
//         Uniswap, or swapping OXEN for wOXEN on the{' '}
//         <a target='_blank' rel='noopener noreferrer' href='/swap'>
//           bridge
//         </a>
//         .
//       </p>
//       <p>
//         Head to the{' '}
//         <a
//           target='_blank'
//           rel='noopener noreferrer'
//           href='https://app.uniswap.org/#/swap'
//         >
//           Uniswap application
//         </a>{' '}
//         and connect your wallet of choice, such as{' '}
//         <a
//           target='_blank'
//           rel='noopener noreferrer'
//           href='https://metamask.io/'
//         >
//           Metamask
//         </a>
//         , then head{' '}
//         <a
//           target='_blank'
//           rel='noopener noreferrer'
//           href='https://info.uniswap.org/pair/0xbaeca7c35346a8d31811ef971f38603012a12c1e'
//         >
//           here
//         </a>{' '}
//         to add liquidity to the wOXEN/USDT pool. Once you have added liquidity,
//         Uniswap will send a liquidity provider token to your connected wallet.
//       </p>
//       <p>
//         Once you have the liquidity provider tokens from Uniswap, navigate back
//         to the wOXEN bridge and connect your wallet.
//       </p>
//       <p>
//         You can then stake your <b>liquidity provider tokens</b> in order to
//         start earning rewards through the Liquidity Rewards Program.
//       </p>

//       <h2>
//         Do I need to unstake from Uniswap to stake on the wOXEN smart contract
//         instead?
//       </h2>
//       <p>
//         No. If you unstake from Uniswap, you will not be able to earn rewards
//         through the Liquidity Rewards Program.
//       </p>
//       <p>
//         You should use the liquidity provider tokens from Uniswap to stake into
//         the wOXEN smart contract — not wOXEN or USDT.
//       </p>

//       <h2>What will I be rewarded with?</h2>
//       <p>You will be rewarded with wOXEN.</p>
//       <p>
//         You can easily swap your wOXEN rewards for OXEN using the{' '}
//         <a target='_blank' rel='noopener noreferrer' href='/swap'>
//           wOXEN bridge
//         </a>
//         , or swap them to USDT using Uniswap.
//       </p>

//       <h2>How much wOXEN will I get?</h2>
//       <p>
//         Currently, 4,320 wOXEN is being rewarded each day. Your rewards will
//         depend on the amount of liquidity you provide.
//       </p>
//       <p>
//         If your wallet is connected, you can view estimated APY and wOXEN earned
//         on this page.
//       </p>

//       <h2>How often are rewards given?</h2>
//       <p>
//         Each block, the smart contract calculates rewards proportional to each
//         liquidity provider for each second since the previous block.
//       </p>
//       <p>
//         Essentially, this means you’re earning rewards for every second you have
//         staked into the smart contract, although rewards are only allocated once
//         each block, and can only be claimed when unstaking or by using the claim
//         rewards button.
//       </p>

//       <h2>
//         I don’t see my staked wOXEN and USDT on Uniswap after staking into the
//         wOXEN smart contract.
//       </h2>
//       <p>
//         The wOXEN contract is holding the Uniswap LP tokens which represent your
//         share of the Uniswap liquidity pool. If you want your LP tokens back,
//         you can unstake from the wOXEN smart contract, however you will no
//         longer receive rewards as a part of the Liquidity Rewards Program.
//       </p>

//       <h2>
//         Do I still earn Uniswap rewards while I am staked into the wOXEN smart
//         contract?
//       </h2>
//       <p>
//         Yes. Uniswap uses liquidity provider tokens to track how much liquidity
//         you have in any given pair. When you stake these tokens to the wOXEN
//         smart contract, they no longer appear in your wallet, so Uniswap
//         displays $0 liquidity provided.
//       </p>
//       <p>
//         When you unstake from the wOXEN smart contract, your liquidity provider
//         tokens will be returned and you will be able to claim your Uniswap
//         rewards.
//       </p>

//       <h2>What happens when the reward period ends?</h2>
//       <p>
//         Once the reward period ends, no more rewards will be paid for staking
//         into the wOXEN smart contract. This smart contract may be used again for
//         future Liquidity Rewards Programs, but there is no reason for you to
//         stay staked in the wOXEN smart contract when there are no rewards.
//       </p>

//       <h2>Do I need to restake for future reward periods?</h2>
//       <p>
//         No. If you keep your liquidity provider tokens staked, you will be ready
//         to earn rewards immediately for future Liquidity Rewards Programs.
//       </p>

//       <h2>
//         I’ve connected my wallet, but the ‘stake all’ button doesn’t appear.
//       </h2>
//       <p>
//         This button will only appear once you have staked into the wOXEN/USDT
//         Uniswap pool and connected your wallet to the wOXEN bridge.
//       </p>
//       <p>
//         If this button is still not appearing, make sure the wallet you have
//         connected to the wOXEN bridge is the same wallet you used to stake on
//         Uniswap.
//       </p>

//       <h2>What happens if I unstake from the contract?</h2>
//       <p>
//         Your liquidity provider tokens will be returned to your wallet along
//         with any earned rewards. You will no longer receive rewards as a part of
//         the Liquidity Rewards Program.
//       </p>
//     </Box>
//   );
// }

function liquidityInfo() {
  return (
    <Box>
      <h3>Announcement</h3>

      <p>
        The foundation is now finishing up the oxen liquidity rewards program.
        We would like to thank all who participated. For clarification the
        woxen/usdt uniswap pair will still be available for trading and the app
        will still allow bridging between woxen and oxen, however rewards for
        providing liquidity to the uniswap pool are no longer available.
      </p>
    </Box>
  );
}

export function StakingInfo() {
  return (
    <Box m='8px' mt='3em' mb='2em' className='readable'>
      {liquidityInfo()}
      {/* {faq()} */}
    </Box>
  );
}
