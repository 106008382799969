import React, { Component } from 'react';
import Box from '@mui/material/Box';

class About extends Component {
  render() {
    return (
      <div>
        <Box display='flex' justifyContent='center' className='title'>
          <h1 className='textCenter'>About wOxen</h1>
        </Box>
        <Box m='8px' mt='3em' mb='2em' className='readable'>
          <p>
            Oxen is a blockchain project that allows users to trade and
            communicate with increased levels of privacy. Oxen runs on its own
            Monero-based blockchain, meaning Oxen by itself can’t be used to
            interact with many popular Ethereum-based tools.
          </p>
          <p>
            In order to provide users greater access to Oxen, The Oxen
            Foundation is providing a wrapping service with the support and
            oversight of the Oxen Foundation.
          </p>
          <p>
            Wrapped Oxen, or wOxen, is an ERC20-compatible token issued by the
            Oxen Foundation on the Ethereum blockchain that can be exchanged for
            native Oxen tokens on the Oxen blockchain.
          </p>

          <h2>How does it work?</h2>
          <p>
            wOxen is minted under the oversight of the Oxen Foundation. Every
            single wOxen in circulation is fully backed by a Oxen coin stored on
            the Oxen blockchain. When you deposit Oxen into the wOxen bridge,
            you will receive wOxen tokens to your specified wallet address on
            the Ethereum blockchain.
          </p>
          <p>
            When it’s time to turn your wOxen back into Oxen tokens, you can
            unwrap wOxen by provably burning it through the bridge software. You
            must use a Web3.0 compatible wallet such as Metamask, which will
            unwrap your Oxen by automatically including your Oxen address in the
            burn transaction that you send through the wOxen bridge.
          </p>

          <h2>How safe is wOxen?</h2>
          <p>
            The wOxen smart contract is a direct copy of the highly audited wBTC
            smart contract, with a minor change that has been approved by
            several external Ethereum developers. To see the contents of the
            smart contract and the details of the changes, head to{' '}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://github.com/oxen-bridge/wLoki-smart-contract'
            >
              https://github.com/oxen-bridge/wLoki-smart-contract
            </a>
          </p>
          <p>
            The wOxen bridge is provided to you by . We strongly recommend you
            read the full terms of service, and understand that this is
            fundamentally a custodial service for the time being. With correct
            oversight being provided by The Oxen Foundation, there is little
            scope for the bridge to go down irrecoverably. However, users of
            wOxen should be aware that The Oxen Foundation unable to provide an
            absolute guarantee that wOxen will be redeemable 1:1 with Oxen
            forever, and users should asses the risks before deciding to hold
            wOxen.
          </p>
        </Box>
      </div>
    );
  }
}

export default About;
