const config = {
  apiUrl: 'https://ethereum.oxen.io',
  useAPIEncryption: false,
  woxen: {
    walletCreationUrl: 'https://metamask.io',
    txExplorerUrl: 'https://etherscan.io/tx'
  },
  oxen: {
    walletCreationUrl:
      'https://docs.oxen.io/oxen-docs/using-the-oxen-blockchain/oxen-wallet-guides',
    txExplorerUrl: 'https://oxen.observer/tx'
  }
};

export default config;
