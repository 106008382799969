import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { CheckCircle, Triangle } from 'react-feather';

import { useActiveWeb3React } from '../../hooks';
import { getEtherscanLink } from '../../utils';
import { ExternalLink } from '../../theme';
import { useAllTransactions } from '../../state/transactions/hooks';
import { RowFixed } from '../Row';
import Loader from '../Loader';

const TransactionWrapper = styled.div``;

const TransactionStatusText = styled.div`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  :hover {
    text-decoration: underline;
  }
`;

const TransactionState = styled(ExternalLink)<{
  pending: number;
  success?: number;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  border-radius: 0.5rem;
  padding: 0.25rem 0rem;
  font-weight: 500;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.primary1};
`;

const StyledIconWrapper = styled.div<{ pending: number; success?: number }>`
  color: ${({ pending, success, theme }) =>
    pending === 1 ? theme.primary1 : success === 1 ? theme.green1 : theme.red1};
`;

const IconWrapper = (props: {
  children: ReactNode;
  pending: number;
  success?: number;
}) => {
  const { children, pending, success } = props;
  return (
    <StyledIconWrapper pending={pending} success={success}>
      {children}
    </StyledIconWrapper>
  );
};

export default function Transaction({ hash }: { hash: string }) {
  const { chainId } = useActiveWeb3React();
  const allTransactions = useAllTransactions();

  const tx = allTransactions?.[hash];
  const summary = tx?.summary;
  const pending = !tx?.receipt;
  const success =
    !pending &&
    tx &&
    (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined');

  if (!chainId) return null;

  return (
    <TransactionWrapper>
      <TransactionState
        href={getEtherscanLink(chainId, hash, 'transaction')}
        pending={pending ? 1 : 0}
        success={success ? 1 : 0}
      >
        <RowFixed>
          <TransactionStatusText>{summary ?? hash} ↗</TransactionStatusText>
        </RowFixed>
        <IconWrapper pending={pending ? 1 : 0} success={success ? 1 : 0}>
          {pending ? (
            <Loader />
          ) : success ? (
            <CheckCircle size='16' />
          ) : (
            <Triangle size='16' />
          )}
        </IconWrapper>
      </TransactionState>
    </TransactionWrapper>
  );
}
