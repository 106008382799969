import axios, { Method } from 'axios';
import { EventEmitter } from 'events';
import { Warning } from 'utils/error';
import config from 'config';
import * as Actions from './actions';
import * as Events from './events';
import dispatcher from './dispatcher';

const { apiUrl } = config;

const httpClient = axios.create({ baseURL: apiUrl });
const endpoints = {
  getInfo: '/api/v1/getInfo',
  getUnconfirmedTransactions: '/api/v1/getUnconfirmedTransactions',
  getSwaps: '/api/v1/getSwaps',
  swap: '/api/v1/swap',
  createBNBAccount: '/api/v1/createBNBAccount',
  downloadBNBKeystore: '/api/v1/downloadBNBKeystore'
};

class Store extends EventEmitter {
  store: any;

  constructor() {
    super();
    this.store = {};

    dispatcher.register(async (payload: any) => {
      switch (payload.type) {
        case Actions.GET_INFO:
          this.getInfo();
          break;
        case Actions.GET_UNCONFIRMED_TXS:
          this.getUnconfirmedTransactions(payload);
          break;
        case Actions.GET_SWAPS:
          this.getSwaps(payload);
          break;
        case Actions.SWAP_TOKEN:
          this.swapToken(payload);
          break;
        case Actions.FINALIZE_SWAP_TOKEN:
          // TODO this function isn't define anywhere even though it is in the store?
          // this.finalizeSwap(payload);
          break;
        default:
          break;
      }
    });
  }

  getStore(key: string) {
    return this.store[key];
  }

  async getInfo() {
    try {
      const data = await this.fetch(endpoints.getInfo, 'GET');
      this.store.info = data.result;
      this.emit(Events.FETCHED_INFO, data.result);
    } catch (e) {
      this.emit(Events.ERROR, e);
    }
  }

  async getUnconfirmedTransactions(payload: any) {
    try {
      const data = await this.fetch(
        endpoints.getUnconfirmedTransactions,
        'GET',
        payload.content
      );
      this.emit(Events.FETCHED_UNCONFIRMED_TXS, data.result);
    } catch (e) {
      this.emit(Events.ERROR, e);
    }
  }

  async getSwaps(payload: any) {
    try {
      const data = await this.fetch(endpoints.getSwaps, 'GET', payload.content);
      this.emit(Events.FETCHED_SWAPS, data.result);
    } catch (e) {
      this.emit(Events.ERROR, e);
    }
  }

  async swapToken(payload: any) {
    try {
      const data = await this.fetch(endpoints.swap, 'POST', payload.content);
      this.emit(Events.TOKEN_SWAPPED, data.result);
    } catch (e) {
      this.emit(Events.ERROR, e);
    }
  }

  async fetch(url: string, method: Method, params = null) {
    const field = method.toLowerCase() === 'post' ? 'data' : 'params';
    try {
      const { data } = await httpClient({
        method,
        url,
        [field]: params
      });

      if (data.status === 200 && !data.success) {
        throw new Warning(data.result);
      }

      return data;
    } catch (e) {
      console.log(`Failed fetch ${url}: `, e);

      // If we got an error from the api then throw it
      if ((e as any).response && (e as any).response.data) {
        throw new Error((e as any).response.data.result);
      }

      // Some other error occurred
      throw e;
    }
  }
}

const store = new Store();
export default store;
